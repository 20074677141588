import SecondaryButton from "components/buttons/secondary";
import { CharityIcon, QRIcon } from "icons";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import { VolunteerEventItem } from "types/volunteer";
import { formatDate, formatTime, verifyImg } from "utils/helper";
import routes from "utils/routes";

import * as S from "./volunteer-event-card.styled";

export interface IVolunteerEventCardProps {
  isBigCard?: boolean;
  isAdminView?: boolean;
}

const btnStyle = {
  maxWidth: "100%",
  width: "230px",
  alignSelf: "center",
};

const VolunteerEventCard: React.FC<VolunteerEventItem & IVolunteerEventCardProps> = ({
  id,
  isAdminView,
  name,
  isBigCard = false,
  charityLogoUrl,
  startDateTime,
  endDateTime,
  status,
  charityName,
  attending,
  atCapacity,
  availableSpots,
  charityLocation,
  address,
}): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [imgValid, setImgValid] = React.useState<boolean>(true);

  const onCardClick = (): void => {
    navigate(`${routes.VOLUNTEER.EVENT}/${id}`);
  };

  const viewSignUps = (): void => {
    navigate(`${routes.VOLUNTEER.EVENT_ATTENDEES}/${id}/1`);
  };

  React.useEffect(() => {
    verifyImg(charityLogoUrl, setImgValid);
  }, [charityLogoUrl]);

  const getCityState = () => {
    const parts = address.split(",");
    const trimmedParts = parts.map((part) => part.trim());

    const city = trimmedParts.length > 1 ? trimmedParts[trimmedParts.length - 3] : "";
    const stateZip = trimmedParts.length > 2 ? trimmedParts[trimmedParts.length - 2] : "";
    const state = stateZip.split(" ")[0] || "";

    return `${city}, ${state}`;
  };

  return (
    <S.VolunteerEventCardContainer isBigCard={isBigCard} onClick={onCardClick}>
      <S.VolunteerEventCardGradientContainer />
      <S.VolunteerEventCardInnerContainer
        alignItems="flex-start"
        justifyContent="space-between"
        isBigCard={isBigCard}
      >
        <S.ColumnDisplay alignItems="flex-start">
          {charityLogoUrl && imgValid ? (
            <S.VolunteerEventImg src={charityLogoUrl} alt="" />
          ) : (
            <CharityIcon height={44} />
          )}
          <S.Separator height={30} />
          <S.VolunteerEventName>{name}</S.VolunteerEventName>
          <S.Separator height={30} />
          {startDateTime && (
            <>
              <S.VolunteerEventAddress>
                {`${formatDate(startDateTime, "MM/DD/YYYY")} at ${formatTime(startDateTime)}`}
              </S.VolunteerEventAddress>
              <S.Separator height={20} />
            </>
          )}
          {address && <S.VolunteerEventAddress>{getCityState()}</S.VolunteerEventAddress>}
          <S.Separator height={30} />
          {atCapacity ? (
            <S.EventBannerFull>Event Full</S.EventBannerFull>
          ) : (
            <S.EventBannerAvailable>Spots Available</S.EventBannerAvailable>
            // availableSpots && (
            //   <S.EventBannerAvailable>{availableSpots} spots left</S.EventBannerAvailable>
            // )
          )}
        </S.ColumnDisplay>
      </S.VolunteerEventCardInnerContainer>
    </S.VolunteerEventCardContainer>
  );
};

VolunteerEventCard.defaultProps = {
  isBigCard: false,
  isAdminView: false,
};

export default VolunteerEventCard;
